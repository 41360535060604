import React from 'react'
import { Link } from 'gatsby'
import H4 from '~/components/new/typography/h4'
import Button from '~/components/button/button'

import styled from 'styled-components'

/**
 * @param {Object} props
 * @param {string} [props.buttonText=View positions]
 * @param {string} [props.label=See more]
 * @param {string} [props.slug=/careers/jobs/]
 * @param {string} [props.title=Browse all open positions]
 */
export const SeeMoreJobsCard = props => {
  const {
    buttonText = 'View positions',
    label = 'See more',
    slug = '/careers/jobs/',
    title = 'Browse all open positions',
    ...rest
  } = props
  return (
    <StyledSeeMoreJobsCard {...rest}>
      <Link to={slug}>
        <div className='see-more-jobs-card-label'>{label}</div>
        <H4 as='p' className='see-more-jobs-card-title'>
          {title}
        </H4>
        <Button as='div' color='yellow' size='small'>
          {buttonText}
        </Button>
      </Link>
    </StyledSeeMoreJobsCard>
  )
}

const StyledSeeMoreJobsCard = styled.div`
  background-color: ${props => props.theme.color.g400};

  a {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: ${props => props.theme.size.m};
    text-decoration: none;

    :hover,
    :focus {
      .title {
        text-decoration: underline;
      }
    }
  }

  .see-more-jobs-card-label {
    color: ${props => props.theme.color.g50};
    font-size: 16px;
  }

  .see-more-jobs-card-title {
    color: #fff;
    flex-grow: 2;
    line-height: 1.35;
    margin: 8px 0 16px;
  }
`
