import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import H4 from 'components/new/typography/h4'
import P from 'components/new/typography/p'

import formatDate from 'utils/dates/format-date'

/**
 * @param {Object} props
 * @param {Array<string>} props.careerAreas
 * @param {string} props.locationsText
 * @param {string} props.publishedDate
 * @param {string} props.slug
 * @param {string} props.title
 * @param {string} props.type
 */
export const JobRequisitionCard = props => {
  const { careerAreas, locationsText, publishedDate, slug, title, type, ...rest } = props
  return (
    <StyledJobRequisitionCard {...rest}>
      <Link to={slug}>
        <div className='career-areas'>
          {careerAreas.map((area, index) => (
            <React.Fragment key={area}>
              {index !== 0 ? <span>&bull;</span> : null}
              <span>{area}</span>
            </React.Fragment>
          ))}
        </div>
        <H4 as='p' className='title'>
          {title}
        </H4>
        <P className='locations'>{locationsText ? locationsText : ''}</P>
        <div className='details'>
          {type} &bull; Posted {formatDate(new Date(publishedDate))}
        </div>
      </Link>
    </StyledJobRequisitionCard>
  )
}

const StyledJobRequisitionCard = styled.div`
  background-color: #fff;

  a {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: ${props => props.theme.size.m};
    text-decoration: none;

    :hover,
    :focus {
      .title {
        text-decoration: underline;
      }
    }
  }

  .career-areas {
    color: ${props => props.theme.color.g400};
    font-size: 16px;

    span {
      display: inline-block;
      margin: 0 4px 0 0;
    }
  }

  .title {
    color: ${props => props.theme.color.n900};
    line-height: 1.35;
    margin: 8px 0 0;
  }

  .locations {
    color: ${props => props.theme.color.n400};
    flex-grow: 2;
    line-height: 1.35;
    margin: 4px 0 16px;
  }

  .details {
    color: ${props => props.theme.color.n100};
    font-size: 14px;
    line-height: 1.35;
  }
`
