import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/layout'
import Content from '~/components/new/content'
import Spacer from '~/components/new/spacer'
import { H1, H2, P } from '~/components/new/typography'
import LocationsMapSearch from '~/components/locations-map/locations-map-search'
import { JobRequisitionCard } from '~/components/new/cards/job-requisition-card'
import { BreadcrumbsSection } from '~/components/new/breadcrumbs/breadcrumbs-section'
import { BreadcrumbsNav } from '~/components/new/breadcrumbs/breadcrumbs-nav'
import { Seo } from 'components/seo'

import styled from 'styled-components'
import { clearfix } from 'styles/clearfix'
import { column } from 'styles/column'

import { useQuery } from '@tanstack/react-query'

import { fetchAllJobRequisitions } from 'utils/sanity/job-requisitions'
import toHandle from 'utils/to-handle'
import { SeeMoreJobsCard } from '~/components/new/cards/see-more-jobs-card'

/**
 * @param {import('gatsby').PageProps<LocationsDirectoryStateTemplateData, LocationsDirectoryStateTemplatePageContext>} props
 */
const LocationsDirectoryStateTemplate = props => {
  const {
    data: {
      allSanityLocation: { nodes: allSanityLocationNodes },
      locationStats: { totalCount },
    },
    pageContext: { state },
  } = props

  const breadcrumbs = [
    {
      name: 'Hutson Inc',
      displayName: 'Home',
      link: '/',
    },
    {
      name: 'Locations',
      link: '/locations/',
    },
    {
      name: 'Directory',
      link: '/locations/directory/',
    },
    {
      name: state,
      link: `/locations/directory/${toHandle(state)}/`,
    },
  ]

  return (
    <Layout kind='white'>
      <Seo
        title={`${state} Locations`}
        description={`Hutson Inc has ${totalCount} ${
          totalCount > 1 ? 'stores' : 'store'
        } in ${state}. Find a Hutson store near you for great products, service, and parts.`}
        breadcrumbs={breadcrumbs}
      />
      <PageContent>
        <BreadcrumbsSection>
          <BreadcrumbsNav items={breadcrumbs} />
        </BreadcrumbsSection>
        <Content kind='full' className='top-content'>
          <H1>{state} Locations</H1>
          <P className='subtitle'>
            {totalCount} locations in {state}
          </P>
        </Content>
        <LocationsMapSearch locations={allSanityLocationNodes} />
        <CareersSection state={state} />
      </PageContent>
    </Layout>
  )
}

const PageContent = styled.div`
  .top-content {
    padding: 24px 0 36px;
  }

  @media (min-width: 900px) {
    .top-content {
      padding: 48px 64px 64px;
    }
  }

  .subtitle {
    margin: 0;
  }
`

const CareersSection = props => {
  const { state } = props
  /**
   * @type {import('@tanstack/react-query').UseQueryResult<Array<import('utils/sanity/job-requisitions').JobRequisitionCardData>>}
   */
  const JobRequisitionsQueryResponse = useQuery({
    queryKey: ['job-requisitions', { limit: 3, state }],
    staleTime: 1000 * 60 * 5, // 5 minutes
    queryFn: async () => {
      /**
       * @type {Array<import('utils/sanity/job-requisitions').JobRequisitionCardData>}
       */
      const response = await fetchAllJobRequisitions({ limit: 3, state })

      return response
    },
    keepPreviousData: true,
  })

  return JobRequisitionsQueryResponse.isFetched &&
    Array.isArray(JobRequisitionsQueryResponse.data) &&
    JobRequisitionsQueryResponse.data.length > 0 ? (
    <LightBackground>
      <Content kind='full'>
        <H2>Careers at Hutson, Inc. in {state}</H2>
        <Spacer size='m' />
        <JobRequisitionsGrid>
          {JobRequisitionsQueryResponse.data.map(jobRequisition => (
            <JobRequisitionCard
              key={jobRequisition.key}
              careerAreas={jobRequisition.careerAreas}
              locationsText={jobRequisition.locationsText}
              publishedDate={jobRequisition.publishedDate}
              slug={jobRequisition.slug}
              title={jobRequisition.title}
              type={jobRequisition.type}
            />
          ))}
          <SeeMoreJobsCard
            slug={`/careers/jobs/?State=${state}`}
            title={`Browse all open positions in ${state}`}
          />
        </JobRequisitionsGrid>
      </Content>
    </LightBackground>
  ) : null
}

const LightBackground = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const JobRequisitionsGrid = styled.div`
  > * {
    margin-bottom: 16px;
  }

  @media (min-width: 600px) {
    ${clearfix};
    display: flex;
    flex-wrap: wrap;

    > * {
      ${column('1/2', 16)};
    }
  }

  @media (min-width: 1000px) {
    > * {
      ${column('1/4', 16)};
      margin-bottom: 0;
    }
  }
`

/**
 * @typedef LocationsDirectoryStateTemplatePageContext
 * @property {string} state
 */

/**
 * @typedef LocationsDirectoryStateTemplateData
 * @property {Object} allSanityLocation
 * @property {Object[]} allSanityLocation.nodes
 * @property {string} allSanityLocation.nodes.address
 * @property {string} allSanityLocation.nodes.city
 * @property {Object} allSanityLocation.nodes.geolocation
 * @property {number} allSanityLocation.nodes.geolocation.lat
 * @property {number} allSanityLocation.nodes.geolocation.lng
 * @property {Object} allSanityLocation.nodes.hours
 * @property {string} allSanityLocation.nodes.hours.sunday
 * @property {string} allSanityLocation.nodes.hours.monday
 * @property {string} allSanityLocation.nodes.hours.tuesday
 * @property {string} allSanityLocation.nodes.hours.wednesday
 * @property {string} allSanityLocation.nodes.hours.thursday
 * @property {string} allSanityLocation.nodes.hours.friday
 * @property {string} allSanityLocation.nodes.hours.saturday
 * @property {Object[]} allSanityLocation.nodes.images
 * @property {import('gatsby-plugin-image').IGatsbyImageData} allSanityLocation.nodes.images.asset
 * @property {number} allSanityLocation.nodes.locationNumber
 * @property {string} allSanityLocation.nodes.phoneNumber
 * @property {string} allSanityLocation.nodes.slug
 * @property {Object[]} allSanityLocation.nodes.specialHours
 * @property {string} allSanityLocation.nodes.specialHours.date
 * @property {string} allSanityLocation.nodes.specialHours.hours
 * @property {string} allSanityLocation.nodes.specialHours.title
 * @property {string} allSanityLocation.nodes.state
 * @property {('open'|'temporarily-closed'|'permanently-closed')} allSanityLocation.nodes.status
 * @property {string} allSanityLocation.nodes.title
 * @property {number} allSanityLocation.nodes.zip
 * @property {Object} locationStats
 * @property {number} locationStats.totalCount
 */

export const pageQuery = graphql`
  query locationsDirectoryStateTemplate($state: String!) {
    allSanityLocation(
      filter: { status: { ne: "permanently-closed" }, state: { eq: $state } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        address
        city
        geolocation {
          lat
          lng
        }
        hours {
          sunday
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
        }
        images {
          asset {
            gatsbyImageData(width: 680)
          }
        }
        locationNumber
        phoneNumber
        slug
        specialHours {
          date
          hours
          title
        }
        state
        status
        title
        zip
      }
    }
    locationStats: allSanityLocation(
      filter: {
        state: { eq: $state }
        status: { ne: "permanently-closed" }
        retailLocation: { eq: true }
      }
    ) {
      totalCount
    }
  }
`

export default LocationsDirectoryStateTemplate
